<template>
  <div class="flex flex-col min-w-0 flex-1 space-y-1">
    <div v-tooltipable class="text-sm truncate text-gray-400">
      {{ title }}
    </div>
    <div class="text-gray-600 overflow-x-auto scrollbar-thin">
      <div class="whitespace-nowrap">{{ formattedValue }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { OfferFieldDetails } from "@/models/offers";
import { FieldType } from "@/enums/offers";
import {
  formatDateCustom,
  formatMoney,
  formatPercentage
} from "@/helpers/formatting";
import { computed } from "vue";

const props = defineProps<{
  offerField: OfferFieldDetails;
}>();

const formatter: Record<
  FieldType,
  (value: string | number) => string | number
> = {
  [FieldType.INTEGER]: (value: string | number) => {
    return value ?? "-";
  },
  [FieldType.FLOAT]: (value: string | number) => {
    return value ?? "-";
  },
  [FieldType.PERCENT]: (value: string | number) => {
    return formatPercentage(value, 3) ?? "-";
  },
  [FieldType.STRING]: (value: string | number) => {
    return value ?? "-";
  },
  [FieldType.URL]: (value: string | number) => {
    return value ?? "-";
  },
  [FieldType.CURRENCY]: (value: string | number) => {
    return formatMoney(value, 3);
  },
  [FieldType.OPTIONS]: (value: string | number) => {
    if (value === null) {
      return "-";
    }
    if (Array.isArray(value)) {
      return (props.offerField.options as string[])?.[value as number] ?? "-";
    }
    return (
      (props.offerField.options as Record<string | number, string>)?.[value] ??
      "-"
    );
  },
  [FieldType.DATE]: (value: string | number) => {
    return formatDateCustom(value, "MMM dd, yyyy");
  },
  [FieldType.TEXTAREA]: (value: string | number) => {
    return value ?? "-";
  },
  [FieldType.DEFAULT]: (value: string | number) => {
    return value ?? "-";
  }
};

const formattedValue = computed(() => {
  if (props.offerField.value === null) {
    return "-";
  }
  return formatter[props.offerField.type](props.offerField.value);
});

const title = computed(() => {
  return props.offerField.label.trim() || "-";
});
</script>
